import logo from './logo.svg';
import './App.css';
import 'fomantic-ui-css/semantic.css';

import _ from 'lodash'

import React from 'react'
import faker from 'faker'
import { Component } from 'react';
import { Container, Header, Loader, Label, Grid, Image, Dropdown } from 'semantic-ui-react';

class App extends Component {
  render() {
    return (
      <Container>
        <Header>
          Fomantic-UI & Semantic-UI-React & Hi~ Jenkins!!
        </Header>
        <Loader active inline className="slow red" />
        <Loader active inline className="fast green" />

        <div class="ui slider" id="slider-1"></div>
        <div class="ui input">
          <input type="text" id="slider-input-1" disabled="" />
        </div>

        <Header as="h3">This example is powered by Semantic UI React  😊</Header>

        <ContainerExampleText></ContainerExampleText>
        <GridExampleGrid></GridExampleGrid>
        <DropdownExampleMultipleSearchSelection></DropdownExampleMultipleSearchSelection>
      </Container>
    );
  }
}


class LabelExampleImage extends Component {
  render() {
    return (
      <Container>
        <div >
          <Label as='a' image>
            <img src='https://react.semantic-ui.com/images/avatar/small/joe.jpg' />
      Joe
    </Label>
          <Label as='a' image>
            <img src='https://react.semantic-ui.com/images/avatar/small/elliot.jpg' />
      Elliot
    </Label>
          <Label as='a' image>
            <img src='https://react.semantic-ui.com/images/avatar/small/stevie.jpg' />
      Stevie
    </Label>
        </div>
      </Container>
    );
  }
}

const ContainerExampleText = () => (
  <Container text>
    <Header as='h2'>Header</Header>
    <LabelExampleImage></LabelExampleImage>
    <p>
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo
      ligula eget dolor. Aenean massa strong. Cum sociis natoque penatibus et
      magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,
      ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa
      quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget,
      arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.
      Nullam dictum felis eu pede link mollis pretium. Integer tincidunt. Cras
      dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus.
      Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim.
      Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus
      viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet.
      Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo
      ligula eget dolor. Aenean massa strong. Cum sociis natoque penatibus et
      magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,
      ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa
      quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget,
      arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.
      Nullam dictum felis eu pede link mollis pretium. Integer tincidunt. Cras
      dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus.
      Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim.
      Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus
      viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet.
      Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi.
    </p>
  </Container>
)
const columns = _.times(16, (i) => (
  <Grid.Column key={i}>
    <Image src='https://react.semantic-ui.com/images/wireframe/image.png' />
  </Grid.Column>
))

const GridExampleGrid = () => <Grid>{columns}</Grid>



const addressDefinitions = faker.definitions.address
const stateOptions = _.map(addressDefinitions.state, (state, index) => ({
  key: addressDefinitions.state_abbr[index]+"1",
  text: state,
  value: addressDefinitions.state_abbr[index]+"v",
}))

const DropdownExampleMultipleSearchSelection = () => (
  <Dropdown
    placeholder='State'
    fluid
    multiple
    search
    selection
    options={stateOptions}
  />
)



//export default   App  ;
export { 
    App as default, 
    LabelExampleImage, 
    ContainerExampleText, 
    GridExampleGrid ,
    DropdownExampleMultipleSearchSelection
  };
